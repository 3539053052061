// Media Query Mixins
@mixin mq($minWidth, $maxWidth) {
  @media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin mq-max($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin mq-min($minWidth) {
  @media only screen and (min-width: $minWidth) {
    @content;
  }
}

@mixin align($width: null, $height: null) {
  position: absolute;
  top: 50%;
  left: 50%;
  @if not $width and not $height {
    transform: translate(-50%, -50%);
  } @else if $width and $height {
    width: $width;
    height: $height;
    margin: -($width / 2) #{0 0} -($height / 2);
  } @else if not $height {
    width: $width;
    margin-left: -($width / 2);
    transform: translateY(-50%);
  } @else {
    height: $height;
    margin-top: -($height / 2);
    transform: translateX(-50%);
  }
}

/// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin element($element) {
    &__#{$element} {
        @content;
    }
}

/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier) {
    &--#{$modifier} {
        @content;
    }
}

/// @alias element
@mixin e($element) {
    @include element($element)  {
        @content;
    }
}

/// @alias modifier
@mixin m($modifier) {
    @include modifier($modifier) {
        @content;
    }
}