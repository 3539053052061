// Fonts
$light: 'raleway-light', serif;
$regular: 'raleway-regular', serif;
$medium: 'raleway-medium', serif;
$bold: 'raleway-bold', serif;
$semibold: 'raleway-semibold', serif;
$extrabold: 'raleway-extrabold', serif;

// Sizes
$max-width-content: 1170px;

// Breakpoints
$ph: 600px;
$tp: min-device-width 768px;
$tb: 768px;
$large: 1200px;
$ds: 1024px;
$dl: 1366px;
$hd: 1920px;
$shd: 2560px;

// Colors
$white: #ffffff;
$grey: #3e3e3e;
$blue-light: #229bd2; 
$blue-dark: #002e5d;

// Slick
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;