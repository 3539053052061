#swipebox-overlay {
	background: $white;
}
#swipebox-slider .slide-loading {
  background-image: url('../img/icons/swipebox/loader.gif');
}
#swipebox-arrows {
	width: 100%;
	max-width: 100%;
	height: 78px;
}

#swipebox-prev, 
#swipebox-next,
#swipebox-close {
  background-image: url('../img/icons/swipebox/icons.png');
  width: 39px;
  height: 39px;
  background-size: 120.5px;
  @include mq-min($tb) {
  	background-size: 241px;
  	width: 78px;
  	height: 78px;
  }
}
#swipebox-prev {
	background-position: -27px 0px;
	margin-left: 20px;
	background-color: $white;
	@include mq-min($tb) {
		background-position: -54px 0px;
		margin-left: 105px;
	}
}
#swipebox-next {
	background-position: -81px 0px;
	margin-right: 20px;
	background-color: $white;
	@include mq-min($tb) {
		background-position: -162px 0px;
		margin-right: 105px;
	}
}
#swipebox-close {
	background-position: 0 -13.5px;
	width: 12px;
	height: 12px;
	top: 20px;
	right: 20px;
	@include mq-min($tb) {
		background-position: 0 -27px;
		width: 24px;
		height: 24px;
		top: 74px;
		right: 105px;
	}
}
#swipebox-bottom-bar {
	background: transparent;
	z-index: 1;
	top: 50%;
	margin-top: -19.5px;
	height: 39px;
	bottom: auto;
	@include mq-min($tb) {
		margin-top: -39px;
		height: 78px;
	}
}
#swipebox-bottom-bar.visible-bars {
	transform: none;
}

#swipebox-slider {
	height: 80%;
	top: 10%;
}
.counter {
	width: 100%;
	bottom: 50px;
	position: absolute;
	text-align: center;
	font-family: $bold;
	font-size: 13px;
}