// Slider Gallery Component

$easing: cubic-bezier(.47,.17,.41,.77);
.gallery {
	display: flex;
	height: 100vh;
	align-items: center;
	@include e('container') {
		width: 100%;
		height: 267px;
		margin: auto;
		margin-top: -133px;
		padding: 0;
		position: absolute;
		top: 50%;
		left: 0;
		@include mq-min($tb) {
			height: 567px;
			margin-top: -284px;
		}
		@include mq-min($ds) {
			overflow: hidden;
			top: auto;
			margin: 0;
			padding-top: 60px;
			position: relative;
			width: 100%;
			height: auto;
		}
	}
	@include e('item') {
		opacity: 0;
		transition: transform .3s ease-out, opacity .3s ease-out;
		transform: translate3d(3vw, 0, 0);
		display: block;
		max-width: 100%;
		height: auto;
		margin: 0 10px;
		float: none;
		@include mq-max($ds - 1) {
			transform: translate3d(-3vw, 0, 0);
			vertical-align: middle;
			display: inline-block;
			.slick-initialized & {
				 display: inline-block;
			}
		}
		
		@include mq-min($ds) {
			margin-left: 0;
			margin-right: 30px;
		}
		> a {
			display: block;
			transition: opacity .3s ease-in-out;
			&:hover {
				opacity: .9;
			}
			&:focus {
				outline: 0;
				border: 0;
			}
		}
		&.big {
			img {
				max-height: 267px;
				@include mq-min($tb) {
					max-height: 567px;
				}
				@include mq-min($ds) {
					max-height: 60vh;
					max-width: 1273px;
				}
			}
		}
		&.small {
			img {
				max-height: 267px;
				@include mq-min($tb) {
					max-height: 567px;
				}
				@include mq-min($ds) {
					max-height: 40vh;
					max-width: 790px;
				} 
			}
		}
		img {
			max-width: 100%;
			margin: auto;
			display: block;
			@include mq-min($tb) {
				width: auto;
			}
		}
		.images-out & {
			transform: translate3d(-3vw, 0, 0);
		}
		.images-in & {
			opacity: 1;
			transform: none;
			transition: transform .3s $easing, opacity .3s $easing;

			&:first-child {
				transition-delay: .2s;
				@include mq-min($ds) {
					transition-delay: 0s;
				}
			}
			&:nth-child(2) {
				transition-delay: .2s;
			}
			&:nth-child(3) {
				transition-delay: .4s;
			}
			&:nth-child(4) {
				transition-delay: .6s;
			}
		}
		&.hide {
			display: none;
		}
	}
	.slider {
		position: static;
	}
	.slick-track {
		width: 100%;
		margin: 0;
		padding: 0;
		@include mq-min($ds) {
			margin: 0;
			display: flex;
			//flex-wrap: wrap;
			align-items: center;
			margin: 0;
			padding: 0;
		}
	}
	.slick-prev,
	.slick-next {
		position: absolute;
    background-image: url('../img/icons/sprite_arrow-slider.png');
    background-size: 78px;
    top: 50%;
    width: 39px;
    height: 39px;
    margin-top: -19.5px;
    display: block;
    text-indent:-9999px;
    border: 0;
    z-index: 2;
    cursor: pointer;
    @include mq-min($ds) {
    	background-size: 156px;
    	width: 78px;
    	height: 78px;
    	margin-top: -5px;
    }
    &:after {
    	content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../img/icons/sprite_arrow-slider.png');
			background-size: 78px;
			transition: opacity .3s ease-in-out;
			opacity: 0;
			background-position: 0 0;
			@include mq-min($tb) {
				background-size: 156px;
			}
    }
    &:focus {
    	border: 0;
    	outline: none;
    }
    &:hover {
    	&:after {
    		opacity: 1;
    	}
    }
	}
	.slick-list {
		z-index: 2;
	}
	.slick-prev {
		left: 10px;
    background-position: 0 0;
    z-index: 3;
    &:after {
    	background-position: 0 center;
    }
    &.slick-disabled {
    	background-position: 0 bottom;
    	&:after {
    		transition: none;
    	}
    	&:hover {
    		&:after {
    			background-position: 0 bottom;
    		}
    	}
    }
   
	}
	.slick-next {
		right: 10px;
    background-position: right 0;
    z-index: 3;
    &:after {
			background-position: right center;
    }
    &.slick-disabled {
    	background-position: right bottom;
    	&:after {
    		transition: none;
    	}
    	&:hover {
    		&:after {
    			background-position: right bottom;
    		}
    	}
    }
	}
	.slick-dots {
		position: absolute;
		bottom: -55px;
		list-style: none;
		display: block;
		text-align: center;
		padding: 0;
		margin: 0;
		width: 100%;
		li {
			position: relative;
			display: inline-block;
			height: 20px;
			width: 20px;
			margin: 0 5px;
			padding: 0;
			cursor: pointer;
			button {
				border: 0;
				background: transparent;
				display: block;
				height: 40px;
				width: 40px;
				outline: none;
				line-height: 0px;
				font-size: 0px;
				color: transparent;
				padding: 5px;
				cursor: pointer;
				&:hover, &:focus {
					outline: none;
					&:before {
						opacity: $slick-opacity-on-hover;
					}
				}
				&:before {
					position: absolute;
					top: 0;
					left: 0;
					content: "";
					border-radius: 100%;
					background: $blue-light;
					width: 10px;
					height: 10px;
					font-family: $slick-font-family;
					font-size: $slick-dot-size;
					line-height: 20px;
					text-align: center;
					color: $slick-dot-color;
					opacity: $slick-opacity-not-active;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
			}
			&.slick-active button:before {
				color: $slick-dot-color-active;
				opacity: $slick-opacity-default;
			}
		}
	}
}