.filter {
	position: absolute;
	left: 20px;
	top: 80px;
	z-index: 4;
	max-width: 250px;
	@include mq-min($ds) {
		left: 60px;
		top: 15vh;
	}
	&--not-active {
		z-index: 1;
	}
	@include e('link') {
		color: $grey;
		font-family: $light;
		font-size: 20px;
		text-decoration: none;
		display: flex;
		align-items: flex-end;
		@include mq-min($ds) {
			font-size: 28px;
		}
	}

	@include e('arrow') {
		display: block;
		margin-left: 6px;
		margin-top: 0;
		transform: none;
		transform-origin: 50% 65%;
		transition: transform .3s ease-in-out;
		.filter--open & {
			transform: rotateX(180deg);
		}
	}
	@include e('list') {
		display: block;
		margin-top: 25px;
		padding: 25px;
		position: relative;
		max-height: 100%;
		min-height: 183px;
		box-sizing: border-box;
		@include mq-min($tb) {
			padding: 40px;
			min-height: 246px;
		}
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $blue-light;
			transition: transform .3s ease-in-out;
			transform-origin: 0 0;
			z-index: -1;
			transform: scaleY(1);
			transition-delay: 0s;
		}	
	}
	@include m('hide') {
		&:after {
			transform: scaleY(0);
			transition-delay: .1s;
		}
		
	}
	@include e('item') {
		margin-bottom: 15px;
		opacity: 1;
		transition: opacity .3s ease-in-out;
		transition-delay: .15s;
		@include mq-min($tb) {
			margin-bottom: 30px;
		} 
		.filter--hide & {
			transform: none;
			opacity: 0;
			transition-duration: .1s;
		}
		&:first-child {
		}
		&:last-child {
			margin-bottom: 0px;
		}
		a {
			display: block;
			font-family: $medium;
			font-size: 16px;
			text-decoration: none;
			color: $white;
			transition: color .3s ease-in-out;
			@include mq-min($tb) {
				font-size: 20px;
			}
			&:hover, &.active {
				color: $blue-dark;
			}
		}
	}
	
}